import { BlogDetailsData } from '@/types/blog'
import Paths from '@/config/paths'
import { Page } from '@/components/page/Page'
import SEO from '@/utils/SEO'
import { Image } from '@/components/images'
import EdithInFrontOfHouse from '@/images/blog/edith-austin-tampa-house-for-sale.jpg'
import EdithFLRealtors from '@/images/blog/edith-austin-florida-realtors.jpg'
import EdithStateFair from '@/images/blog/edith-austin-florida-state-fair.jpg'
import EdithWithDogs from '@/images/blog/edith-austin-dogs-halloween.jpg'

export const BlogVoyageTampa2024Details: BlogDetailsData = {
  title: 'Rising Stars: Meet Edith [Austin] of Tampa, Florida',
  description:
    'I’m thrilled to share my journey in real estate, as featured in Voyage Tampa’s “Rising Stars” series! From my family’s first steps toward homeownership to building a career in Tampa Bay, this blog highlights the challenges I’ve overcome and the passion that drives me to help my clients find their perfect home. Click to read more about my story and why Tampa Bay is such a special place to me!',
  path: Paths.BlogVoyageTampa2024.loc,
  thumbnailImgStr: '/images/blog/thumbnail/edith-austin-reator-tampa-tn.jpg',
}

export const BlogVoyageTampa2024 = () => {
  return (
    <Page headerText='Rising Stars: Meet Edith [Austin] of Tampa, Florida'>
      <SEO
        title="Rising Stars: Meet Edith Austin of Tampa, Florida"
        description="Read about Edith Austin's inspiring journey in real estate, as featured in Voyage Tampa’s Rising Stars series. Learn how her experiences shape her approach to helping clients in Tampa Bay."
        type="article"
        pagePath={Paths.BlogVoyageTampa2024.loc}
        imgFileName="/blog/thumbnail/edith-austin-reator-tampa-tn.jpg"
      />
      <h2>A Journey Shaped by Family and Resilience</h2>
      <p>
        When Voyage Tampa reached out to feature me in their "Rising Stars"
        series, it was both humbling and exciting. Sharing my journey—from my
        family’s first steps toward homeownership to becoming a real estate
        agent in Tampa Bay—was a chance to reflect on how far I’ve come. Today,
        I’m thrilled to share a glimpse of that story with you, along with
        insights from the full interview, which you can read{' '}
        <a
          href="https://voyagetampa.com/interview/rising-stars-meet-edith-guzman-of-tampa-florida/"
          target="_blank"
        >
          here
        </a>
        .
      </p>

      <Image
        src={EdithInFrontOfHouse}
        alt="Edith Austin Realtor Tampa standing in front of house for sale"
      />

      <h2>Why Tampa Bay?</h2>
      <p>
        Tampa Bay isn’t just where I live; it’s where I’ve chosen to build a
        future. From Clearwater’s tranquil beaches to the bustling downtown
        energy, this area has it all. During the interview, I highlighted what
        makes Tampa Bay special: the warm weather, outdoor adventures, and
        diverse attractions. Whether you’re into water sports, exploring parks,
        or enjoying Michelin-starred restaurants, there’s truly something for
        everyone here.
      </p>

      <Image
        src={EdithStateFair}
        alt="Edith Austin Florida State Fair Tampa Bay"
        maxScreenWidth='md'
        caption='Check out incredible views from the Florida State Fair ferris wheel.'
      />

      <h2>Challenges and Lessons Learned</h2>
      <p>
        Becoming a real estate agent wasn’t without its challenges. As a
        newcomer to Tampa Bay, establishing trust and building a client base
        required perseverance. I leaned on Tyler’s tech skills to create a
        strong online presence and dedicated myself to extensive
        training—earning over 88 hours of continuing education credits in just
        three months. Mastering contracts, market trends, and local statistics
        took time, but each challenge shaped me into a better agent.
      </p>

      <h2>My Approach to Real Estate</h2>
      <p>
        What sets me apart as an agent is my analytical background. With a
        Bachelor of Science in Mathematics, I excel at evaluating market trends,
        property values, and financial options. I bring a methodical approach to
        every transaction, creating detailed protocols to ensure transparency
        and efficiency for my clients.
      </p>

      <p>
        I’m particularly passionate about helping first-time homebuyers navigate
        down payment assistance programs and renovation loans. Many people don’t
        realize how accessible homeownership can be with the right guidance. By
        staying informed about these programs, I aim to remove barriers and help
        more people achieve their dreams.
      </p>

      <Image
        src={EdithFLRealtors}
        alt="Edith Austin Florida Realtors continuing education"
        maxScreenWidth='md'
      />

      <h2>Defining Success</h2>
      <p>
        For me, success isn’t just about closing deals; it’s about making a
        lasting impact. In the interview, I shared my goal to increase
        homeownership rates across the U.S. By educating clients and simplifying
        the process, I hope to empower more families to build stability and
        wealth through owning a home. It’s not just a job; it’s a mission.
      </p>

      <h2>Let’s Keep the Conversation Going</h2>
      <p>
        Being featured by Voyage Tampa was an incredible experience, and I’m
        grateful for the opportunity to share my story. If you’d like to dive
        deeper, you can read the full interview{' '}
        <a
          href="https://voyagetampa.com/interview/rising-stars-meet-edith-guzman-of-tampa-florida/"
          target="_blank"
        >
          here
        </a>
        .
      </p>

      <p>
        Whether you’re considering a move to Tampa Bay, buying your first home,
        or exploring new investment opportunities, I’m here to help. Let’s make
        your real estate journey as rewarding and stress-free as possible. Reach
        out today, and let’s start planning your next big step.
      </p>
      <Image
        src={EdithWithDogs}
        alt="Edith Austin with dogs and husband halloween"
        maxScreenWidth='md'
      />
    </Page>
  )
}
