import tw from 'twin.macro'
import styled from 'styled-components'
import { Nav, NavContainer } from '@/components/navbar'
import { Hero } from '../headers'
import Footer from '@/components/footers/Footer'

// Must only have two sections because of flexbox (content and footer sections)
const ContentWithGrowth = styled.div`
  ${tw`
    overflow-hidden
    flex flex-col
    min-h-screen
    mx-auto
    pt-6 lg:pt-10
  `}
`
const ContentSection = tw.div`grow mb-20`

interface PageProps {
  children: React.ReactNode | React.ReactNode[]
  header?: React.ReactNode // Pass in header component
  headerText?: string // text for default header - if no header component is passed
}

export const BasePage = ({ children, header, headerText }: PageProps) => {

  const Header = () => {
    if (header) {
      return header
    } else if (headerText) {
      return <Hero title={headerText} /> // Default header
    } else {
      return <></>
    }
  }

  return (
    <div>
      <NavContainer>
        {/* @ts-ignore */}
        <Nav />
      </NavContainer>
      <Header />
      <ContentWithGrowth>
        <ContentSection>{children}</ContentSection>
        <Footer />
      </ContentWithGrowth>
    </div>
  )
}
