import {Language} from '@/enums/language'

// Sets language upon user visiting page
export const setLanguageInitial = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const currentQueryParam = urlParams.get(Language.QUERY_PARAM)

  if (currentQueryParam !== Language.SPANISH_ABBR) {
    sessionStorage.setItem(Language.LOCAL_STORAGE_VAR, Language.ENGLISH_ABBR)
  } else {
    sessionStorage.setItem(Language.LOCAL_STORAGE_VAR, Language.SPANISH_ABBR)
  }
}

// Toggles local storage and passes in new toggled value to CB
export const toggleLanguage = cb => {
  const currentVal = sessionStorage.getItem(Language.LOCAL_STORAGE_VAR)

  if (currentVal === Language.ENGLISH_ABBR) {
    sessionStorage.setItem(Language.LOCAL_STORAGE_VAR, Language.SPANISH_ABBR)
    cb && cb(Language.SPANISH_ABBR)
    return
  }

  if (currentVal === Language.SPANISH_ABBR) {
    sessionStorage.setItem(Language.LOCAL_STORAGE_VAR, Language.ENGLISH_ABBR)
    cb && cb(Language.ENGLISH_ABBR)
    return
  }
}
