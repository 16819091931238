import Paths from '@/config/paths'
import SEO from '@/utils/SEO'
import { Page } from '@/components/page/Page'
import { GetStartedCenteredBtn } from '@/components/buttons'
import { GetStartedSpacing } from '@/styles/LayoutStyles'

export const SellHome = () => {
  return (
    <>
      <SEO
        title="Sell Your Tampa Bay Home with Confidence"
        description="Sell your Tampa Bay home with confidence. Enjoy a smooth and stress-free process with expert guidance from a dedicated local real estate agent."
        type="website"
        pagePath={Paths.SellHome.loc}
      />
      <Page headerText="Sell Your Tampa Bay Home with Confidence">
        <h2>Selling Your Home Doesn’t Have to Be Overwhelming</h2>
        <p>
          Thinking about selling your home? It’s a big step, and I get
          it—there’s a lot on the line. Whether you’re upgrading, downsizing, or
          relocating, my goal is to make the process smooth, stress-free, and
          yes, even enjoyable.
        </p>

        <h2>It All Starts with the Right Price</h2>
        <p>
          Pricing a home is part science, part art. Too high, and your home
          might sit on the market for months. Too low, and you leave money on
          the table. I’ll conduct a detailed market analysis to determine the
          sweet spot—a price that attracts serious buyers while maximizing your
          return.
        </p>

        <h2>Prepping Your Home for Success</h2>
        <p>
          First impressions matter, especially in real estate. I’ll help you get
          your home market-ready, offering guidance on everything from
          decluttering and staging to tackling minor repairs that can boost your
          home’s value. Need help finding a reliable handyman or painter? I’ve
          got connections.
        </p>

        <h2>Marketing That Works</h2>
        <p>
          Gone are the days of simply putting a “For Sale” sign in the yard and
          hoping for the best. I’ll create a customized marketing plan to
          showcase your home’s best features. Think professional photography,
          virtual tours, and targeted online advertising. And let’s not forget
          good old-fashioned networking—I’ll tap into my local contacts to get
          the word out.
        </p>

        <h2>Negotiating Like a Pro</h2>
        <p>
          When offers start rolling in, that’s when the real work begins. I’ll
          guide you through the negotiation process, ensuring you get the best
          possible terms while avoiding common pitfalls. From contingencies to
          closing costs, I’ll make sure you’re covered.
        </p>

        <h2>Closing the Deal</h2>
        <p>
          Closing can be complicated, but with me on your team, you won’t have
          to sweat the details. I’ll coordinate with the title company, buyer’s
          agent, and anyone else involved to ensure everything goes smoothly.
          Your only job? Start planning your next chapter.
        </p>

        <h2>Let’s Get Your Home Sold</h2>
        <p>
          Selling a home is a journey, but you don’t have to go it alone. With
          me by your side, you’ll have a trusted partner who’s as invested in
          your success as you are. Reach out today, and let’s get your home
          sold.
        </p>
        <GetStartedSpacing>
          <GetStartedCenteredBtn />
        </GetStartedSpacing>
      </Page>
    </>
  )
}
