import styled from 'styled-components'
import tw from 'twin.macro'
import Paths from '@/config/paths'
import SEO from '@/utils/SEO'
import { Page } from '@/components/page/Page'
import { ContactUsForm } from '@/components/forms'

const Container = tw.div`
  md:max-w-[90%] m-auto
`

const ListStyled = styled.ol`
  li {
    ${tw`mb-6`}
  }
`

const ContactStyled = styled.div`
${tw`flex flex-col`}
p { ${tw`my-2 inline-block text-2xl`} }
`

export const GetStarted = () => {
  return (
    <>
      <SEO
        title="Get Started"
        description="Start your Tampa Bay real estate journey with Edith Austin. Whether buying, selling, or relocating, I offer personalized service to make the process smooth and stress-free. Contact me at 805-765-1625 or edith@edithaustin.com today."
        type="website"
        pagePath={Paths.GetStarted.loc}
      />
      <Page headerText="Get Started">
        <h1>Ready to Begin Your Real Estate Journey?</h1>
        <Container>
          <p>
            Whether you're buying your dream home, selling your current
            property, or relocating to Tampa Bay, I’m here to make the process
            seamless and stress-free. Let’s work together to achieve your real
            estate goals with confidence.
          </p>

          <h2>Here’s How to Get Started:</h2>
          <ListStyled>
            <li>
              <strong>Schedule a Consultation</strong>
              <br />
              Let’s discuss your needs, preferences, and goals. Whether in
              person, over the phone, or via email, we’ll create a tailored plan
              to get you where you want to be.
            </li>
            <li>
              <strong>Explore Your Options</strong>
              <br />
              I’ll provide you with expert guidance, valuable insights, and
              curated options that match your unique vision – whether you’re
              buying, selling, or relocating.
            </li>
            <li>
              <strong>Take the First Step</strong>
              <br />
              Reach out today, and let’s start turning your real estate dreams
              into reality!
            </li>
          </ListStyled>
          <ContactStyled>
          <p>
            <strong>📞 Call or Text:</strong>{' '}
            <a href="tel:805-765-1625">805-765-1625</a>
           </p>
           <p>
            <strong>📧 Email:</strong>{' '}
            <a href="mailto:edith@edithaustin.com">edith@edithaustin.com</a>
          </p>
          </ContactStyled>
          <ContactUsForm />
          <h3 tw="mt-1!">
            Let’s get started – I can’t wait to help you find your perfect home!
          </h3>
        </Container>
      </Page>
    </>
  )
}
