import tw from 'twin.macro'
import Paths from '@/config/paths'
import SEO from '@/utils/SEO'
import { Page } from '@/components/page/Page'
import { Image } from '@/components/images'
import CozyHomeImg from '@/images/cozy-comfortable-home-tampa-bay.webp'
import BeautifulHomeImg from '@/images/beautiful-suburban-home-tampa-bay.webp'
import { GetStartedCenteredBtn } from '@/components/buttons'
import { GetStartedSpacing } from '@/styles/LayoutStyles'

export const BuyHome = () => {
  return (
    <>
      <SEO
        title="Find Your Dream Home in Tampa Bay | Trusted Real Estate Agent"
        description="Discover your perfect home in Tampa Bay with expert guidance from a dedicated local real estate agent. Explore charming neighborhoods, hidden gems, and a seamless home-buying experience. Contact us today!"
        type="website"
        pagePath={Paths.BuyHome.loc}
      />
      <Page headerText="Find Your Perfect Home in Tampa Bay">
        <h2>Let’s Make Finding Your Dream Home a Breeze</h2>
        <p>
          Buying a home is a big deal. It’s not just about square footage or
          bedroom counts—it’s about finding a place where your life can unfold
          comfortably, happily, and just the way you’ve always imagined. As a
          dedicated real estate agent here in Tampa Bay, I don’t just help you
          find a house. I help you find <em>your</em> home.
        </p>

        <h2>So, How Do We Start?</h2>
        <p>
          First things first: I’ll listen. What’s your vision? A cozy bungalow
          with a white picket fence? A waterfront condo with stunning sunset
          views? Or maybe something in between? We’ll talk about your
          must-haves, nice-to-haves, and even those “deal breakers” you can’t
          compromise on.
        </p>
        <Image
          src={BeautifulHomeImg}
          alt="A scenic photo of a beautiful suburban home in Tampa Bay, Florida. The house features modern architecture with a spacious lawn, and a white picket fence."
          maxScreenWidth='xs'
        />
        <p>
          Once I get a sense of your priorities, I’ll dig into the Tampa Bay
          real estate market to find homes that check your boxes. And don’t
          worry—you’re not just getting access to the same MLS listings everyone
          else sees. I’ll tap into my network and local insights to uncover
          hidden gems that might not even be on your radar.
        </p>

        <h2>Touring Tampa Bay’s Neighborhoods</h2>
        <p>
          If you’re new to Tampa Bay, you’re in for a treat. Each neighborhood
          has its own unique charm, from the historic vibes of Hyde Park to the
          lively energy of Ybor City. Prefer a laid-back beach town? Let’s
          explore St. Pete Beach or Treasure Island. Want a family-friendly
          suburb? Wesley Chapel and Brandon might be perfect for you. I’ll help
          you navigate it all, so you feel confident in your choice.
        </p>

        <h2>More Than Just a Real Estate Agent</h2>
        <p>
          Here’s the thing: Buying a home isn’t just about numbers and
          contracts. It’s about trust. You need someone who’s in your corner,
          advocating for your best interests every step of the way. That’s where
          I come in. From negotiating offers to coordinating inspections, I’ll
          handle the nitty-gritty details so you can focus on the exciting
          part—imagining your future in your new home.
        </p>

        <Image
          src={CozyHomeImg}
          alt="A cozy and affordable suburban home in Tampa Bay, Florida, featuring a small single-story house with a simple front porch, and a tidy lawn."
          maxScreenWidth='xs'
        />

        <h2>Financing? Let’s Talk Options</h2>
        <p>
          The financial side of buying a home can feel overwhelming, especially
          if it’s your first time. But don’t sweat it—I’ll connect you with
          trusted local lenders who can walk you through the mortgage process.
          Fixed rates, adjustable rates, VA loans, FHA loans—we’ll find the
          right fit for your budget and goals.
        </p>

        <h2>Smooth Sailing from Start to Finish</h2>
        <p>
          Buying a home should be exciting, not stressful. That’s why I’ll be by
          your side from our first conversation until you’ve got keys in hand
          (and beyond!). Whether it’s navigating offers, coordinating with title
          companies, or ensuring your closing day goes off without a hitch, I’ve
          got it covered.
        </p>

        <h2>Ready to Start the Search?</h2>
        <p>
          Let’s make this journey as fun and rewarding as it should be. Drop me
          a message or give me a call, and we’ll start finding the home you’ve
          been dreaming of. Because honestly, there’s nothing I love more than
          helping people find their perfect spot in Tampa Bay.
        </p>
        <GetStartedSpacing>
        <GetStartedCenteredBtn/>
        </GetStartedSpacing>
      </Page>
    </>
  )
}
