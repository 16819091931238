import { NavLink } from '../navbar/Nav'
import { useLocation } from 'react-router-dom'
import checkOnGoogTransPage from '@/utils/googTranslate.ts'
import { checkProduction } from '@/utils/analytics'

export const GoogTranslateButton: () => boolean | JSX.Element = () => {
  const location = useLocation()

  const page = location.pathname.slice(1)

  const googTranslateBtn = (
    <NavLink
      href={
        checkProduction()
          ? `https://edithaustin-com.translate.goog/${page}?_x_tr_sl=auto&_x_tr_tl=es&_x_tr_hl=es&_x_tr_pto=wapp`
          : `https://dev-edithaustin-com.translate.goog/${page}?_x_tr_sl=auto&_x_tr_tl=es&_x_tr_hl=es&_x_tr_pto=wapp`
      }
    >
      Ve en Espanol
    </NavLink>
  )

  return checkOnGoogTransPage() && googTranslateBtn
}
