import { BasePage } from './BasePage'
import {
  DefaultPageStyles,
  ContentContainer,
} from '../../styles/page'

interface PageProps {
  children: React.ReactNode | React.ReactNode[]
  header?: React.ReactNode // Pass in header component
  headerText?: string
}

export const Page = ({ children, header, headerText }: PageProps) => {
  return (
    <BasePage header={header} headerText={headerText}>
      <ContentContainer>
        <DefaultPageStyles>{children}</DefaultPageStyles>
      </ContentContainer>
    </BasePage>
  )
}
