import tw from 'twin.macro'
import styled from 'styled-components'
import { Nav, NavContainer } from '@/components/navbar'
import Paths from '@/config/paths.js'
import { Hero } from '@/components/headers'
import Features from '@/components/features/ThreeColSimple.js'
import { PrimaryButtonWhite } from '@/styles/Buttons.js'
import { BlogDetails } from '@/components/blog/BlogDetails'
import ContactMe from '@/components/cta/DownloadApp.js'
import Footer from '@/components/footers/Footer.js'
import emailIcon from '@/images/icons/email-icon.svg'
import phoneIcon from '@/images/icons/phone-icon.svg'
import formalPhoto from '@/images/formal-head-shot.jpg'
import tampaHouse from '@/images/tampa-house-water-cr.jpg'
import mapLocation from '@/images/map-location.svg'
import houseSun from '@/images/icons/house-sun-icon.svg'
import houseWater from '@/images/icons/house-water-icon.svg'
import { AboutDetails } from '@/pages/AboutPage'
import { BlogPropertyTaxes2024Details } from '@/pages/blog'
import { GetStartedCenteredBtn } from '@/components/buttons'

const HomePageStyled = styled.div`
  p,
  ul,
  li {
    ${tw`
      md:text-xl
  `}
  }
`

const MobilePadding = tw.div`px-5 lg:px-0`

const featureCards = [
  {
    imageSrc: houseWater,
    title: 'Find a home',
    description: 'Connect with me to find the home of your dreams',
    url: Paths.BuyHome.loc
  },
  {
    imageSrc: houseSun,
    title: 'Sell your home',
    description: 'Find the right buyer for your home',
    url: Paths.SellHome.loc
  },
  {
    imageSrc: mapLocation,
    title: 'Relocate',
    description: 'Let me help you relocate to Tampa Bay',
    url: Paths.Relocate.loc
  },
]

const AboutDetailsHome = {
  title: 'About Me: Get to Know Edith Austin',
  description:
    'Discover my journey from relocating to Tampa Bay to becoming a trusted expert in the area. Learn about my dedication to helping clients find homes that fit their lifestyles, my knowledge of the area’s unique statistics, and how my personalized approach has made a difference for buyers and sellers alike.',
  path: AboutDetails.path,
  thumbnailImgStr: AboutDetails.thumbnailImgStr,
}

const BlogPropertyTaxes2024DetailsHome = {
  title: 'How to Save Money on Property Taxes in Tampa Bay, Florida',
  description:
    'Uncover valuable tips and strategies to reduce your property taxes and maximize your savings.',
  path: BlogPropertyTaxes2024Details.path,
  thumbnailImgStr: BlogPropertyTaxes2024Details.thumbnailImgStr,
}

export default () => {
  return (
    <HomePageStyled>
      <NavContainer>
        <Nav />
      </NavContainer>

      <Hero title="Welcome to Your Gateway to Tampa Bay Living" />

      <section>
        <div tw="max-w-screen-xl m-auto py-16">
          <MobilePadding>
            <h2 tw="text-center mt-8 mb-10 md:mb-20">
              Connecting You to the Home You Deserve
            </h2>
          </MobilePadding>
          <div tw="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <img
                src={formalPhoto}
                alt="Edith Austin"
                tw="rounded-lg m-auto max-w-[20rem] lg:max-w-[26rem] mt-4 mb-4 lg:mb-0"
              />
            </div>
            <div>
              <MobilePadding>
                <p tw="leading-loose">
                  Hi, I’m <strong>Edith Austin</strong>, your trusted real
                  estate expert specializing in Tampa Bay’s vibrant
                  neighborhoods. Whether you’re seeking the perfect waterfront
                  view, a cozy suburban retreat, or an exciting downtown
                  lifestyle, I’m here to guide you every step of the way.
                  <br />
                  <br />
                  With <strong>personalized service</strong> tailored to your
                  unique needs, I’ll listen, plan, and deliver results that make
                  your home-buying or selling experience seamless. My deep
                  knowledge of Tampa Bay’s market trends and communities ensures
                  you’ll have the insight needed to make informed decisions.
                  Together, we’ll turn your real estate dreams into reality
                  while making the process enjoyable and stress-free.
                </p>
              </MobilePadding>
            </div>
          </div>
        </div>
      </section>

      <section tw="bg-[#c4e1f2] py-20">
        <MobilePadding>
          <h2 tw="text-center my-6">
            Buying, Selling, or Relocating? Let Me Guide You Home in Tampa Bay
          </h2>
        </MobilePadding>
        <Features cards={featureCards} />
        <GetStartedCenteredBtn tw="lg:mt-0" variant='white'/>
      </section>

      <section>
        <MobilePadding>
          <div tw="max-w-screen-xl m-auto pt-8 pb-4 lg:py-16">
            <h2 tw="text-center mt-8 mb-4">
              I invite you to take a look around my website
            </h2>
            <p tw="max-w-screen-lg m-auto text-center mb-0">
              Check out these articles and gain the insights you need to make
              informed real estate decisions. Let’s work together to turn your
              dreams into reality!
            </p>
            <BlogDetails details={AboutDetailsHome} imgMaxHeight="11rem" />
            <BlogDetails
              details={BlogPropertyTaxes2024DetailsHome}
              imgMaxWidth="10rem"
            />
            <br /> <br />
          </div>
        </MobilePadding>
      </section>

      <section id="contact-me" tw="overflow-hidden">
        <ContactMe
          text={
            <>
              Let's connect and explore how I can assist you in achieving your
              real estate goals. Your vision is my priority, and together, we
              can make it a reality.
              <br />
              <br />
              Reach out, and let's begin this exciting journey!
            </>
          }
          link1Text="(805) 765-1625"
          link1Url="tel:805-765-1625"
          link1IconSrc={phoneIcon}
          link2Text="edith@edithaustin.com"
          link2Url="mailto:edith@edithaustin.com"
          link2IconSrc={emailIcon}
          imageSrc={tampaHouse}
        />
      </section>

      <Footer />
    </HomePageStyled>
  )
}
