import tw from'twin.macro'
import 'styled-components/macro'

export type MaxScreenWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined

interface ImageProps {
  src: string,
  alt: string,
  caption?: string
  maxScreenWidth?: MaxScreenWidth
  maxWidth?: string, // in rem
  maxHeight?: string, // in rem
}

export const Image = ({src, alt, caption, maxScreenWidth, maxHeight, maxWidth} : ImageProps) => {
  return (
    <div tw="text-center mt-8 overflow-hidden" css={!caption && tw`mb-2`}>
      <div tw="flex justify-center items-center">
        <img 
          src={src}
          alt={alt}
          css={[
            maxScreenWidth && getMaxScreenWidth(maxScreenWidth),
            maxWidth && {maxWidth: `${maxWidth}rem`},
            maxHeight && {maxHeight: `${maxHeight}rem`}
          ]}
        />
      </div>
      {caption && <p tw="mt-4 !mb-0 italic">{caption}</p>}
    </div>
  )
}

export function getMaxScreenWidth (width: MaxScreenWidth) {
  switch(width) {
    case('xs'):
      return tw`max-w-xs`
    case('sm'):
      return tw`max-w-xs sm:max-w-screen-sm`
    case('md'):
      return tw`max-w-xs sm:max-w-screen-sm md:max-w-screen-md`
    case('lg'):
      return tw`max-w-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg`
    case('xl'):
      return tw`max-w-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl`
    default:
      return tw`max-w-xs sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl`
  }
}