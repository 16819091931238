import tw from 'twin.macro'
import styled from 'styled-components'
import { ReactComponent as FacebookIcon } from '@/images/icons/socialColor/icons8-facebook.svg'
import { ReactComponent as InstagramIcon } from '@/images/icons/socialColor/icons8-instagram.svg'
import { ReactComponent as LinkedIn } from '@/images/icons/socialColor/icons8-linkedin.svg'
import { ReactComponent as YoutubeIcon } from '@/images/icons/socialColor/icons8-youtube.svg'
import RealtorIcon from '@/images/icons/socialColor/realtor-icon.png'
import { Social } from '@/config/social'

const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block mx-2 md:mx-4`}
  svg, img {
    ${tw`w-10 h-10 md:w-16 md:h-16`}
  }
  img {
    ${tw`p-[5px] md:p-[8px]`}
  }
`

export const SocialLinks = () => {
  return (
    <div className="social_links">
      <SocialLink href={Social.Instagram} target='_blank'>
        <InstagramIcon />
      </SocialLink>
      <SocialLink href={Social.Youtube} target='_blank'>
        <YoutubeIcon />
      </SocialLink>
      <SocialLink href={Social.Facebook} target='_blank'>
        <FacebookIcon />
      </SocialLink>
      <SocialLink href={Social.Linkedin} target='_blank'>
        <LinkedIn />
      </SocialLink>
      <SocialLink href={Social.Realtor} target='_blank'>
        <img className="realtor-icon"src={RealtorIcon} alt="Icon of Realtor.com" />
      </SocialLink>
    </div>
  )
}
