import tw from 'twin.macro'
import Paths from '@/config/paths'
import SEO from '@/utils/SEO'
import { Page } from '@/components/page/Page'
import { GetStartedCenteredBtn } from '@/components/buttons'
import { GetStartedSpacing } from '@/styles/LayoutStyles'


export const Relocate = () => {
  return (
    <>
      <SEO
        title="Relocating to Tampa Bay? Let’s Make It Easy"
        description="Relocating to Tampa Bay? Get expert help to make your move seamless and stress-free. Learn about neighborhoods, local tips, and virtual home buying services."
        type="website"
        pagePath={Paths.Relocate.loc}
      />
      <Page headerText="Relocating to Tampa Bay? Let’s Make It Easy">
        <h2>From One Transplant to Another</h2>
        <p>
          Moving to a new city is exciting—and a little nerve-wracking. As
          someone who’s relocated to Tampa Bay myself, I know exactly what
          you’re feeling. The good news? You don’t have to navigate this
          transition alone. Whether you’re coming here for work, family, or
          simply a change of scenery, I’ll help you settle in with ease.
        </p>

        <h2>Let’s Find the Right Neighborhood for You</h2>
        <p>
          Tampa Bay has a little bit of everything. Love the beach? You’ll adore
          Clearwater and St. Pete. Prefer an urban vibe? Downtown Tampa and
          Channelside are buzzing with activity. Looking for outdoor recreation
          or family-friendly neighborhoods? South Tampa and New Tampa offer
          fantastic options. Together, we’ll figure out which neighborhood feels
          like home.
        </p>

        <h2>Your Local Guide to Tampa Bay</h2>
        <p>
          When I moved here, I had to figure out everything—from the best
          grocery stores to the quickest routes to the airport. Now, I’ve got
          all that knowledge, and I’m ready to share it with you. Need
          recommendations for restaurants, local attractions, or weekend
          activities? Just ask. I’ll even help you find a trustworthy moving
          company to make the logistics easier.
        </p>

        <h2>Virtual Home Buying? No Problem</h2>
        <p>
          If you’re relocating from out of town, you might not have time to tour
          homes in person. No worries—I specialize in helping long-distance
          buyers. Through virtual showings, detailed walkthrough videos, and
          constant communication, I’ll make sure you feel confident in your
          choice, even from miles away.
        </p>

        <h2>Settling In: Beyond the Sale</h2>
        <p>
          My job doesn’t end when you sign on the dotted line. I’ll be here to
          answer your questions and help you get acclimated. Whether it’s
          finding the best parks for your dog or recommending a great local
          coffee shop, I’m just a call away.
        </p>

        <h2>Ready to Make the Move?</h2>
        <p>
          Relocating is a big adventure, but it doesn’t have to be a stressful
          one. Let’s make your move to Tampa Bay smooth, simple, and—dare I say
          it—fun. Get in touch today, and let’s get started on this exciting new
          chapter.
        </p>
        <GetStartedSpacing>
          <GetStartedCenteredBtn />
        </GetStartedSpacing>
      </Page>
    </>
  )
}
