import tw from 'twin.macro'
import Paths from '@/config/paths'
import { PrimaryButton, PrimaryButtonWhite } from '@/styles/Buttons'

interface GetStartedCenteredBtnProps {
  variant?: 'primary' | 'white'
}

export const GetStartedCenteredBtn = ({
  variant,
}: GetStartedCenteredBtnProps) => {
  const buttonTitle = 'Get Started'
  return (
    <div tw="text-center">
      <a href={Paths.GetStarted.loc}>
        {variant === 'white' ? (
          <PrimaryButtonWhite>{buttonTitle}</PrimaryButtonWhite>
        ) : (
          <PrimaryButton>{buttonTitle}</PrimaryButton>
        )}
      </a>
    </div>
  )
}
