import styled from 'styled-components'
import tw from 'twin.macro'
import { Container, ContentWithPaddingXl } from '@/styles/LayoutStyles'
import { ReactComponent as ArrowRightIcon } from '@/images/icons/arrow-right-icon.svg'
import { BlackUnderlineLink } from '@/styles/Links'

const ThreeColumnContainer = styled.div`
  ${tw`-mt-8 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 
    mb-12 lg:mb-0 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100 mb-2`}
    img {
      ${tw`w-16`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-2xl leading-none pb-1`}
  }

  .description {
    ${tw`mt-4 text-lg font-medium`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`

export default ({
  cards,
  linkText = '',
  imageContainerCss = null,
  imageCss = null,
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>

        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <BlackUnderlineLink className="title">{card.title}</BlackUnderlineLink>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
    </Container>
  )
}
