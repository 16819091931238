import styled from 'styled-components'
import tw from 'twin.macro'
import harborIslandImg from '@/images/headers/tampa-bay-harbor-island.jpg'
interface HeroContainerProps {
  bgImage?: string
}
const HeroContainer = styled.div<HeroContainerProps>`
  ${tw`
    flex
    flex-col
    justify-center
    items-center
    h-[80vh]
    md:h-[70vh]
    p-5
  `}
  background-image: url(${props => props.bgImage ?? harborIslandImg});
  h1 {
    ${tw`text-4xl lg:text-7xl text-center max-w-screen-lg text-white`}
    text-shadow: 1px 1px 15px black;
  }
  
`

interface HeroProps {
  title: string | JSX.Element
  backgroundImg?: string
}
export const Hero = ({ title, backgroundImg }: HeroProps) => {
  return (
    <HeroContainer bgImage={backgroundImg}>
      <h1>{title}</h1>
    </HeroContainer>
  )
}
